const FloatLabel = (() => {
	// add active class and placeholder 
	const handleFocus = (e) => {
		const target = e.target;
		const wrapper = target.closest('.float-input__container')

		if (!wrapper.classList.contains('active')) {
			wrapper.classList.add('active');
		}
		if (!wrapper.classList.contains('focus')) {
			wrapper.classList.add('focus');
		}
		if (target.getAttribute('data-placeholder') !== null) {
			target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
		}
	};

		// remove active class and placeholder
	const handleBlur = (e) => {
		const target = e.target;
		const wrapper = target.closest('.float-input__container')

		if(!target.value) {
			if (wrapper.classList.contains('active')) {
				wrapper.classList.remove('active');
			}
			if (wrapper.classList.contains('focus')) {
				target.parentNode.classList.remove('focus');
			}
		}else{
			if (wrapper.classList.contains('focus')) {
				wrapper.classList.remove('focus');
			}
		}
		target.removeAttribute('placeholder');    
	};  
	// register events
	const bindEvents = (element) => {
		const floatField = element.querySelector('input, textarea');
		floatField.addEventListener('focus', handleFocus);
		floatField.addEventListener('blur', handleBlur);    
	};

	// get DOM elements
	const init = () => {
		const floatContainers = document.querySelectorAll('.float-input__container');
		floatContainers.forEach((element) => {
			let input = element.querySelector('input, textarea');
			if (input != null) {
				if (input.value) {
					element.classList.contains('active');
					if (!element.classList.contains('active')) {
						element.classList.add('active');
					}
					if (!element.classList.contains('focus')) {
						element.classList.add('focus');
					}
				}
				bindEvents(element);
			}
		});
	};
	return {
		init: init
	};
})();
export default FloatLabel;