// polyfills
import 'promise-polyfill/src/polyfill'
import 'element-closest-polyfill'
import 'element-remove'

import Vue from 'vue'
import objectFitImages from 'object-fit-images'
import {EventBus} from './event-bus'
import { Validator, ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules'
import barcoder from 'barcoder'
import SlideUpDown from 'vue-slide-up-down'
import lozad from 'lozad'
import Vue2TouchEvents from 'vue2-touch-events'
import FloatLabel from './floatlabel.js'
import Flickity from 'flickity'
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock'
import Accordion from "accordion/src/accordion.mjs";
import axios from 'axios'

//font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {faFacebookSquare, faInstagram, faTwitter, faFacebookF} from '@fortawesome/free-brands-svg-icons'
import {faArrowRight, faArrowLeft, faSearch, faChevronDown, faArrowToBottom, faTimes, faPrint, faInfoCircle, faShoppingCart} from '@fortawesome/pro-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
library.add(faArrowRight, faArrowLeft, faSearch, faFacebookSquare, faInstagram, faTwitter, faChevronDown, faArrowToBottom, faTimes, faPrint, faInfoCircle, faShoppingCart)

// Import our CSS
import styles from '../css/app.pcss'
import 'lightgallery.js/dist/css/lightgallery.css'
import "accordion/src/accordion.css"

window.lozadObserver = lozad('.lozad', {
	loaded: function (el) {
		const cropPos = el.getAttribute('data-crop-pos')
		if (cropPos) {
			const img = el.getElementsByTagName('img')
			if (img) {
				img[0].style.objectPosition = cropPos
			}
		}
	}
})

window.axios = axios

// SET DEFAULT AXIOS settings
let csrfToken = document.head.querySelector('meta[name="csrf-token"]')
if (csrfToken) {
	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content
} else {
	console.error('CSRF token not found.')
}

// App main
const main = async () => {
	// Async load the vue module
	const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue')
	const VueCookies = await import ( /* webpackChunkName: "vuecookies" */ 'vue-cookies')
	const axios = await import( /* webpackChunkName: "axios" */ 'axios')
	const VueAxios = await import( /* webpackChunkName: "vue-axios" */ 'vue-axios')
	window.CustomMixItUp = await import( /* webpackChunkName: "custommixitup" */ './CustomMixItUp.js')

	Vue.component('font-awesome-icon', FontAwesomeIcon)
	Vue.use(VueCookies)
	Vue.use(axios)
	Vue.use(VueAxios)
	Vue.use(Vue2TouchEvents)
	Vue.component('slide-up-down', SlideUpDown)

	// Create our vue instance
	const footer = new Vue({ //vue object for footer (font awesome)
		el: "footer"
	});

	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		components: {
			'competition-calendar': () => import(/* webpackChunkName: "competition-calendar" */ '../vue/CompetitionCalendar.vue'),
			'recreation-calendar': () => import(/* webpackChunkName: "recreational-calendar" */ '../vue/RecreationCalendar.vue'),
			'competition-results': () => import(/* webpackChunkName: "competition-results" */ '../vue/CompetitionResults.vue'),
			'competition-results-detail': () => import(/* webpackChunkName: "competition-results-detail" */ '../vue/CompetitionResultsDetail.vue'),
			'competition-results-detail-heading': () => import(/* webpackChunkName: "competition-results-detail-heading" */ '../vue/CompetitionResultsDetailHeading.vue'),
			'essobbcct-ranking': () => import(/* webpackChunkName: "essobbcct-ranking" */ '../vue/EssobbcctRanking.vue'),
			'result-block': () => import(/* webpackChunkName: "result-block" */ '../vue/ResultBlock.vue'),
			'notification': () => import(/* webpackChunkName: "notification" */ '../vue/Notification.vue'),
			'checkout-form': () => import(/* webpackChunkName: "variant-choice" */ '../vue/CheckoutForm.vue'),
		},
		data: {
			openMobile: false,
			showModal: false,
			activeMenuDropdown: null,
			closeTimer: false,
			provinceCloseTimer: false,
			cookieConsented: false,
			provinceActive: false,

			messageText: '',
			messageType: 'notice',
			loading: false,
			
			// Aanvraag voor toelating wedstrijd buitenland formulier
			birthday: null,
			nationality: "BEL",

			questionActiveItemId: null,
		},

		methods: {

			toggleMobile(e) {//toggle mobile menu
				this.openMobile = !this.openMobile;
				if (this.openMobile) {
					disableBodyScroll(document.getElementById('mobile-menu-wrapper'));
				} else {
					clearAllBodyScrollLocks();
				}
			},

			checkMobile(e) {//close mobile menu on >medium
				if (window.matchMedia('(min-width: 640px)').matches) {
					this.openMobile = false;
					this.activeMenuDropdown = null;
					clearAllBodyScrollLocks();
				}
			},

			toggleProvinces(event) {
				var self = this;
				if (this.openMobile && event.type == 'click') {
					event.stopPropagation();
					event.preventDefault();
					this.provinceActive = !this.provinceActive;
					if (this.provinceActive) { //scroll on mobile if click open
						if (document.getElementById('#mobile-menu-wrapper') != null) {
							let container = document.getElementById('mobile-menu-wrapper');
							let currentTarget = event.currentTarget;
							setTimeout(function () {
								let pxToScroll = (114 - currentTarget.getBoundingClientRect().top) * -1;
								container.scrollBy(0, pxToScroll);
							}, 200);
						}
					}
				} else if (!this.openMobile && event.type == 'click') {
					event.stopPropagation();
					event.preventDefault();
				} else if (!this.openMobile && event.type == 'mouseover') {
					this.provinceActive = true;
					if (this.provinceCloseTimer) {
						clearTimeout(this.provinceCloseTimer);
					}
				} else if (!this.openMobile && event.type == 'mouseout') {
					this.provinceCloseTimer = setTimeout(function () {
						self.provinceActive = false;
					}, 20);
				}
				if (this.provinceActive) {
					this.activeMenuDropdown = null;
				}
			},

			touchMobileAccordion(slug) {
				return function (event) {
					if (window.matchMedia('(min-width: 640px)').matches) { // only for tablets
						this.activeMenuDropdown = this.activeMenuDropdown === slug ? null : slug;
						if (this.openMobile) {
							if (this.activeMenuDropdown != null && document.getElementById('mobile-menu-wrapper') != null) { //scroll on mobile if click open
								let container = document.getElementById('mobile-menu-wrapper');
								let currentTarget = event.currentTarget;
								setTimeout(function () {
									let pxToScroll = (114 - currentTarget.getBoundingClientRect().top) * -1;
									container.scrollBy(0, pxToScroll);
								}, 200);
							}
						}
					}
				}
			},

			touchOutOfMobileAccordion() {
				return function (event) {
					if (window.matchMedia('(min-width: 640px)').matches) { // only for tablets
						if (this.activeMenuDropdown != null) {
							event.stopPropagation();
							event.preventDefault();
							this.activeMenuDropdown = null;
						}
					}
				}
			},

			toggleMobileAccordion(slug, event) { //toggle mobile menu dropdown
				var _this = this;
				if (this.openMobile && event.type == 'click') {
					event.stopPropagation();
					event.preventDefault();
					this.activeMenuDropdown = this.activeMenuDropdown === slug ? null : slug;
					if (this.activeMenuDropdown != null && document.getElementById('mobile-menu-wrapper') != null) { //scroll on mobile if click open
						let container = document.getElementById('mobile-menu-wrapper');
						let currentTarget = event.currentTarget;
						setTimeout(function () {
							let pxToScroll = (114 - currentTarget.getBoundingClientRect().top) * -1;
							container.scrollBy(0, pxToScroll);
						}, 200);
					}
				} else if (!this.openMobile && event.type == 'click') {
					event.stopPropagation();
					event.preventDefault();
				} else if (!this.openMobile && event.type == 'mouseover') {
					this.activeMenuDropdown = slug;
					if (this.closeTimer) {
						clearTimeout(this.closeTimer);
					}
				} else if (!this.openMobile && event.type == 'mouseout') {
					this.closeTimer = setTimeout(function () {
						_this.activeMenuDropdown = _this.activeMenuDropdown === slug ? null : slug;
					}, 20);
				}
			},
			
			initAdmissionCompetitionAbroadForm() {
				const vm = this
				const birthdayField = document.querySelector("[data-fui-id='toelating-wedstrijd-buitenland-geboortedatum']")
				const nationalityField = document.querySelector("[data-fui-id='toelating-wedstrijd-buitenland-nationaliteit']")
				
				if (birthdayField && nationalityField) {
					birthdayField.addEventListener('change', e => {
						vm.birthday = e.target.value
						vm.outputUciCode()
					})
					
					nationalityField.addEventListener('change', e => {
						vm.nationality = e.target.value
						vm.outputUciCode()
					})
				}
			},
			
			outputUciCode() {
				const outputField = document.querySelector(".uci-code-output .fui-input-container")
				let uciCode = ""
				if (this.nationality && this.birthday) uciCode = this.nationality + this.birthday.replaceAll("-", "")
				if (outputField) {} outputField.textContent = uciCode
			},

			toggleFaqAccordion(id) {
				this.questionActiveItemId = this.questionActiveItemId === id ? '' : id;
			}
		},
		
		mounted() {
			
			// Aanvraag voor toelating wedstrijd buitenland formulier
			this.initAdmissionCompetitionAbroadForm();
			
			// Sliders
			let sliders = document.querySelectorAll('.partners__slider');
			[...sliders].forEach(function (slider) {
				// Show
				slider.classList.remove('hidden');
				// Trigger redraw for transition
				slider.offsetHeight;
				let flkty = new Flickity(slider, {
					// options
					cellAlign: 'center',
					contain: true,
					wrapAround: true,
					freeScroll: false,
					autoPlay: 3000,
					initialIndex: 0,
					pageDots: false,
					prevNextButtons: false,
					selectedAttraction: 0.01,
					friction: 0.15
				});
				let prev = slider.closest('.partners__slider--wrapper').querySelector('.flickity-button.prev');
				let next = slider.closest('.partners__slider--wrapper').querySelector('.flickity-button.next');
				if (prev) {
					prev.addEventListener('click', () => {
						flkty.previous();
					});
				}
				if (next) {
					next.addEventListener('click', () => {
						flkty.next();
					});
				}
			});
			let that = this;
			window.onresize = function (event) {
				that.checkMobile();
			};
			window.lozadObserver.observe();
			if (window.Formie) {
				window.Formie.initForms();
			}
		}
	});

	return vm;
};

// Execute async function
main().then((vm) => {
	objectFitImages();
	//accordion
	for (const el of document.querySelectorAll(".accordion")) {
		const accordion = new Accordion(el);
	}
	//float input labels
	if (document.querySelectorAll('form .float-input__container').length > 0) {
		FloatLabel.init();
	}
	//light box
	let galleries = Array.from(document.getElementsByClassName('lightgallery'));
	if (galleries.length) {
		require(['lightgallery.js'], function () {
			require(["lg-thumbnail.js"], function () {
				[...galleries].forEach(function (sliderContainer) {
					lightGallery(sliderContainer, {
						cssEasing: 'cubic-bezier(0.25, 0, 0.25, 1)',
						exThumbImage: 'data-exthumbimage',
						thumbnail: true,
						animateThumb: false,
						showThumbByDefault: true
					});
				});
			});
		});
	}
	//mixitup
	// Initialize checkBoxFilter code
	var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
	if (document.getElementsByClassName('mixitup__container').length > 0) {
		let customMixItUp = new window.CustomMixItUp.default('news');
		customMixItUp.init();
	}
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
